<template>
  <div class="analytics-page" id="analyticsPageId">
    <Sidebar
      ref="sidebar"
      title="Analytics"
      :filtersOptions="filtersOptions"
      :hasManyFilters="hasManyFilters"
      :isBigScreen="isBigScreen"
      @apply="handleApply"
      @closeFilter="closeFilter"
      @openFilter="openFilter" />
    <div class="data-streams-container">
      <div v-if="chartsData.length > 0">
        <section
          v-for="(chart, idx) in chartsData"
          :key="idx"
          class="chart-data-wrapper">
          <div v-if="chart?.title" class="chart-data-title">
            <a :href="chart.title.link" target="_blank">
              {{ chart.title.text }}
            </a>
            <div class="monitoring-population" v-if="chart?.title?.population">
              <!-- @vue-ignore -->
              <img :src="$peopleToImpact" />
              &nbsp;
              {{ chart.title.population.toLocaleString('en') }}
            </div>
          </div>
          <section v-if="chart?.data.datasets" class="total-values-wrapper">
            <AnalyticsTotalCards
              :data="chart.data.datasets"
              :bigScreen="isBigScreen" />
          </section>
          <section class="chart-container">
            <AnalyticsChart
              :options="chart.config"
              :data="chart.data"
              :idx="idx"
              :title="chart?.title?.text || 'Data Chart'" />
          </section>
          <section class="summurize-section">
            <AnalyticsTable
              :data="chart.tabelsData"
              :aggregation="chart.aggregation" />
          </section>
          <hr
            v-if="chartsData.length > 1 && idx <= chartsData.length - 1"
            style="color: #810000" />
        </section>
      </div>
    </div>
    <div
      v-if="loading"
      style="
        z-index: 49;
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgb(211 211 211 / 50%);
        display: flex;
        align-items: center;
        justify-content: center;
      ">
      <!-- @vue-ignore -->
      <img :src="$loadingGIF" alt="" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import $ from 'jquery'
  import axios from 'axios'
  import moment from 'moment'
  import { ref, onMounted, nextTick, defineComponent, computed } from 'vue'

  import { FilterOption } from '../sidebar/type/type'
  import {
    getHoursBetweenDates,
    formatDateHourly,
    getMonthsBetweenDates,
    getDatesBetween,
    formatDate,
    organizeDatasets,
    removeDuplicatesScales,
    createScaleObject,
    getDataSetForAccumulative
  } from './services/service'

  import Sidebar from '../sidebar/tsSidebar.vue'
  import AnalyticsTotalCards from './components/AnalyticsTotalCards.vue'
  import AnalyticsChart from './components/AnalyticsChart.vue'
  import AnalyticsTable from './components/AnalyticsTable.vue'
  import { ElNotification } from 'element-plus'

  const props = defineProps<{
    isPublic: boolean
    hasManyFilters: boolean
    filtersOptions: FilterOption[]
  }>()

  defineComponent({
    components: {
      Sidebar,
      AnalyticsTotalCards,
      AnalyticsChart,
      AnalyticsTable
    }
  })

  const sidebar = ref<InstanceType<typeof Sidebar> | null>(null)

  const analyticsConfig = {
    type: 'chart',
    responsive: true,
    maintainAspectRatio: false,
    transitions: {
      show: {
        animations: {
          x: {
            from: 0
          },
          y: {
            from: 0
          }
        }
      },
      hide: {
        animations: {
          x: {
            to: 0
          },
          y: {
            to: 0
          }
        }
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 16,
          boxHeight: 16
        }
      }
    },
    scales: {
      Water: {
        type: 'linear',
        position: 'right',

        ticks: {
          beginAtZero: true,
          callback: function (value, index, ticks) {
            return value.toLocaleString() + ' l'
          }
        },
        title: {
          display: true,
          text: 'Water'
        },
        grid: { display: false }
      },
      Energy: {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: function (value, index, ticks) {
            return value.toLocaleString() + ' Wh'
          }
        },
        title: {
          display: true,
          text: 'Energy'
        },
        grid: { display: true }
      },
      x: {
        ticks: { beginAtZero: true },
        grid: {
          display: false,
          drawBorder: false
        }
      }
    }
  }
  const windowWidth = ref(window.innerWidth)
  const menuExpend = ref(false)
  const loading = ref(false)
  const chartsData: any = ref([])
  const emptyChart = ref({
    config: analyticsConfig,
    data: {
      labels: [],
      datasets: []
    }
  })

  onMounted(async () => {
    let mainHeaderHeight = $('#mainHeader').innerHeight()
    $('#analyticsPageId').css('height', `calc(100vh - ${mainHeaderHeight}px)`)
    window.addEventListener('resize', onResize)

    await nextTick()
    if (sidebar.value) {
      sidebar.value.applyChanges()
    }
  })

  const onResize = () => {
    windowWidth.value = window.innerWidth

    let mainHeaderHeight = $('#mainHeader').innerHeight()

    const windowHeight = $(window).height()

    $('#analyticsPageId').css(
      'height',
      `calc(${windowHeight}px - ${mainHeaderHeight}px)`
    )

    if (windowWidth.value > 991) {
      $('.data-streams-container').css('overflow-y', `auto`)
      $('.data-streams-container').css('height', `unset`)
    } else {
      $('.data-streams-container').css('overflow', `unset`)
      $('.sidebar-view-smallScreen').css('left', `-100%`)
    }
  }

  const isBigScreen = computed(() => {
    return windowWidth.value > 991
  })

  const handleApply = async (filterBy) => {
    // console.log("Filter: ", filterBy)
    console.log('%cFilter:', 'font-weight: bold;', filterBy)

    loading.value = true
    if (!isBigScreen && menuExpend.value) {
      toggleFilter()
    }
    const isOneChart = !filterBy.has_many_charts
    let data = isOneChart ? await getDataForOneMainChart(filterBy) : await getDataForManyCharts(filterBy)
    if ((isOneChart && data) || (!isOneChart && data && data?.length > 0)) {
      chartsData.value = isOneChart ? [data] : data
    } else {
      showNotifications('error', 'No Data for Current Filter')
      chartsData.value = [emptyChart.value]
    }

    loading.value = false
    onResize()
  }

  const getDataForOneMainChart = async (filterBy: {}) => {
    try {
      let configForCurrChart = JSON.parse(JSON.stringify(analyticsConfig))
      const params = createdParams(filterBy)
      let projectCountingFetchData
      let data = await axios.post('/api/analytics/monitorings', {
        ...params,
        function: 'count(measurement_points.project_id) AS nb_projects'
      })
      if (!props.isPublic) projectCountingFetchData = data
      let dataForMainChart = data.data
      let { labels, formmatedLabels } = getLabels(params.aggregation, filterBy)
      let { dataSetsFromDB } = organizeDatasets(
        dataForMainChart,
        labels,
        projectCountingFetchData?.data
      )
      if (dataSetsFromDB.length === 0) return

      let flattenDatasets = dataSetsFromDB
        .flat()
        .filter((set) => set?.data?.length > 0)
      if (flattenDatasets.length === 0) return

      const tempScales = removeDuplicatesScales(
        flattenDatasets.map(({ group, unit }) => ({ group, unit }))
      )
      const currentScales = createScaleObject(tempScales)

      const dataForTabels = dataForMainChart
        .map((data) => getDataSetForAccumulative(data, labels))
        .filter((obj) => obj.data.length !== 0)

      configForCurrChart.scales = currentScales
      return {
        data: {
          labels: formmatedLabels,
          datasets: flattenDatasets
        },
        config: configForCurrChart,
        tabelsData: dataForTabels,
        aggregation: params.aggregation
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getDataForManyCharts = async (filterBy) => {
    const projectsToRender = await getCurrentProjects(filterBy)
    const params = createdParams({ ...filterBy })
    if (projectsToRender.length > 0)
      params.query['id_in'] = projectsToRender.map((p) => p.id)
    params['group_by'] = 'project_id'
    let fetch = await axios.post('/api/analytics/monitorings', params)
    let dataForCharts = JSON.parse(JSON.stringify(fetch.data))
    const projectsData = projectsToRender.map((p) => {
      const projectData = organizeProjectDataForChart(
        p,
        dataForCharts,
        filterBy.aggregation,
        filterBy
      )
      return projectData
        ? projectData
        : {
            data: {
              labels: [],
              datasets: []
            },
            config: analyticsConfig,
            aggregation: 'Daily',
            title: {
              text: p.name,
              population: p.population,
              link: p.donor_page
            }
          }
    })

    return projectsData
  }

  const toggleFilter = () => {
    menuExpend.value = !menuExpend.value
    if (menuExpend.value) {
      $('.sidebar-view-smallScreen').css('left', `0%`)
      setTimeout(async () => {
        await nextTick()
        $('.data-streams-container').css('overflow', `hidden`)
      }, 500)
    } else {
      $('.data-streams-container').css('height', `unset`)
      $('.data-streams-container').css('overflow', `unset`)
      $('.sidebar-view-smallScreen').css('left', `-100%`)
    }
  }

  const getCurrentProjects = async (filterBy) => {
    let fetchProjects = await axios.post('/project_management/projects/grid', {
      totalCount: 2000,
      query: filterBy.query,
      additionalColumns: ['id', 'name', 'population', 'donor_page']
    })
    return fetchProjects.data.data
  }

  const organizeProjectDataForChart = (
    project,
    data,
    aggregation,
    filterBy
  ) => {
    let configForCurrChart = JSON.parse(JSON.stringify(analyticsConfig))
    let onlyCurrentProjectData = data
      .map((obj) => {
        const onlyCurrProjectValuesArr = obj.data.filter(
          (d) => d.project_id === project.id
        )
        return onlyCurrProjectValuesArr.length > 0
          ? { ...obj, data: onlyCurrProjectValuesArr }
          : undefined
      })
      .filter((obj) => obj !== undefined)

    if (onlyCurrentProjectData.length === 0) return
    let { labels, formmatedLabels } = getLabels(aggregation, filterBy)
    let { dataSetsFromDB } = organizeDatasets(onlyCurrentProjectData, labels)
    if (dataSetsFromDB.length === 0) return

    let flattenDatasets = dataSetsFromDB
      .flat()
      .filter((set) => set?.data?.length > 0)
    if (flattenDatasets.length === 0) return

    const tempScales = removeDuplicatesScales(
      flattenDatasets.map(({ group, unit }) => ({ group, unit }))
    )
    const currentScales = createScaleObject(tempScales)
    const dataForTabels = onlyCurrentProjectData
      .map((data) => getDataSetForAccumulative(data, labels))
      .filter((obj) => obj.data.length !== 0)

    configForCurrChart.scales = currentScales
    return {
      data: {
        labels: formmatedLabels,
        datasets: flattenDatasets
      },
      config: configForCurrChart,
      tabelsData: dataForTabels,
      title: {
        text: project.name,
        population: project.population,
        link: project.donor_page_link
      },
      aggregation: aggregation
    }
  }

  const createdParams = (filterBy) => {
    let params = JSON.parse(JSON.stringify(filterBy))
    params['ts_from'] = filterBy.date[0]
    params['ts_to'] = filterBy.date[1]
    delete params.date
    delete params.has_many_charts
    if (!(filterBy?.id?.length === 1 || filterBy?.has_many_charts)) {
      params['value_types'] = ['Accumulative']
    }
    params.query = {
      g: []
    }
    for (const key in filterBy.query) {
      params.query.g.push({
        [key]: filterBy.query[key]
      })
    }
    console.log('%cParams:', 'font-weight: bold;', params)
    return params
  }

  const showNotifications = (type, title) => {
    ElNotification({
      title,
      type
    })
  }

  const getLabels = (aggregation, filterBy) => {
    let labels
    let formmatedLabels
    if (aggregation === 'Hourly') {
      labels = getHoursBetweenDates(filterBy.date)
      formmatedLabels = labels.map((label) => {
        const tempDate = new Date(label)
        const date = tempDate.toLocaleString('en-US', {
          timeZone: 'UTC'
        })
        return formatDateHourly(date)
      })
    } else if (aggregation === 'Monthly') {
      labels = getMonthsBetweenDates(filterBy.date)
      formmatedLabels = labels.map((label) => {
        const date = new Date(label)
        return date.toLocaleString('default', { month: 'short' })
      })
    } else {
      labels = getDatesBetween(filterBy.date)
      formmatedLabels = labels.map((label) =>
        moment(label).format('YYYY-MM-DD')
      )
      formmatedLabels = labels.map((label) => formatDate(label))
    }
    return {
      labels,
      formmatedLabels
    }
  }

  const closeFilter = () => {
    $('.data-streams-container').css('height', `unset`)
    $('.data-streams-container').css('overflow', `unset`)
  }

  const openFilter = () => {
    setTimeout(async () => {
      await nextTick()
      $('.data-streams-container').css('overflow', `hidden`)
    }, 500)
  }
</script>

<style scoped></style>
